import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const siteDataQuery = graphql`
  query MyQuery {
    site {
      metadata: siteMetadata {
        description
        siteUrl
        title
        titleTemplate
      }
    }
    imageFile: file(relativePath: { eq: "Wienerberger-logo.png" }) {
      image: childImageSharp {
        imageData: fluid {
          src
        }
      }
    }
  }
`;

interface Props {
  productName?: string;
  productDescription?: string | null;
}

export const SEO: React.FC<Props> = (props) => {
  const { pathname } = useLocation();
  const { site, imageFile } = useStaticQuery(siteDataQuery);
  const { description, siteUrl, title, titleTemplate } = site.metadata;
  const { productName, productDescription } = props;
  const {
    image: { imageData },
  } = imageFile;
  const imageSource = imageData.src;
  const url = `${siteUrl}${pathname}`;

  const splitPathName = pathname.split('/')[1];
  let capitalizedPathName = '';
  let customTitleTemplate = '';

  if (splitPathName.length > 0) {
    capitalizedPathName =
      splitPathName[0].toUpperCase() + splitPathName.slice(1);
    if (capitalizedPathName.includes('-')) {
      capitalizedPathName =
        capitalizedPathName.split('-')[0] +
        ' ' +
        capitalizedPathName.split('-')[1];
    }
  }

  customTitleTemplate = productName
    ? productName + ' - Wienerberger Webshop'
    : capitalizedPathName + ' - Wienerberger Webshop';

  const siteTitleTemplate =
    capitalizedPathName.length > 0 ? customTitleTemplate : titleTemplate;

  const siteDescription = productDescription ? productDescription : description;

  return (
    <Helmet title={title} titleTemplate={siteTitleTemplate}>
      <link rel="canonical" href={url} />

      <meta
        name="facebook-domain-verification"
        content="s3iwt0l2tza2fqzoby1agvq404jzhi"
      />

      <meta name="description" content={siteDescription} />
      {imageSource && <meta property="image" content={imageSource} />}

      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      {imageSource && <meta property="og:image" content={imageSource} />}

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={siteDescription} />
      {imageSource && <meta property="twitter:image" content={imageSource} />}
    </Helmet>
  );
};
